import { template as template_20b850373aa34af0ad23fbd18bc7ebb2 } from "@ember/template-compiler";
const SidebarSectionMessage = template_20b850373aa34af0ad23fbd18bc7ebb2(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
